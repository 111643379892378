<template>
	<Metier name="securite-industrielle" title="Sécurité Industrielle" :main-menu="[{title: 'Ingénieur Sécurité Industrielle', missions: ['Réalise des analyses de risques et initie les modifications en découlant', 'Rédige les cahiers des charges et les appels d\'offre des prestataires et suit le déroulement des études', 'Participe à la rédaction et à la mise à jour des procédures de sécurité industrielle', 'S\'assure de la conformité des modifications avec la législation et les directives du Groupe', 'Est l\'interlocuteur des prestataires concernant la sécurité industrielle'], competences: ['Formation en génie des procédés du raffinage ou de la pétrochimie', 'Connaissance du raffinage et du milieu pétrolier', 'Connaissance de la réglementation en vigueur concernant les installations classées et les risques technologiques'], qualites: ['Leadership', 'Rigueur', 'Organisation', 'Sens du contact']}, {title: 'Coordinateur Pipeline', missions: ['Coordonne toutes les activités en relation avec les pipelines du site', 'S\'assure de la conformité des opérations avec les réglementations et les directives du Groupe et signale toute anomalie', 'Recherche et propose des solutions d\'optimisation des pipelines', 'Prépare et lance les travaux relatifs aux pipelines', 'Est l\'interlocuteur des administrations publiques concernant les pipelines'], competences: ['Formation technique dans l\'industrie pétrolière, du raffinage ou de la pétrochimie', 'Expérience dans l\'exploitation pétrolière et dans la conduite de projets', 'Connaissance des réglementations concernant les pipelines'], qualites: ['Rigueur', 'Organisation', 'Sens du contact']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
